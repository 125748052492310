import { styled } from '@mui/material/styles'

import { Flex } from 'ui-base/flex/Flex'

export const PageWrapper = styled(Flex)`
  margin: 24px 0;
  width: 100%;
`

export const UpdateForm = styled('form')`
  width: 100%;
`
