import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateWidgetApiWithEffects } from 'api/widgets/mutations/useUpdateWidgetApi'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { useWidgetData } from 'pages/widget/hooks/useWidgetData'
import {
  getWidgetFormValidationSchema,
  getWidgetInitialValues,
  MAX_WIDGET_SIZES,
} from 'pages/widget/update/formUtils/formUtils'
import { NativeWidgetFormDTO } from 'types/widgets/widget'
import { Flex } from 'ui-base/flex/Flex'
import { FormGridSizeSelect } from 'ui-base/form/formGridSizeSelect/FormGridSizeSelect'
import { FormTextArea } from 'ui-base/form/formTextArea/FormTextArea'
import { FormTextInput } from 'ui-base/form/formTextInput/FormTextInput'
import { getFormErrors, unpackApiError } from 'utils/form'

import * as S from 'pages/widget/update/WidgetDetailsPage.styled'

export const NativeWidgetUpdateForm = () => {
  const { t } = useTranslation(['widgets', 'common', 'errors'])
  const { showToast } = useToast()
  const { widget } = useWidgetData()
  const { mutateAsync: handleUpdateWidget } = useUpdateWidgetApiWithEffects()

  const form = useForm<NativeWidgetFormDTO>({
    defaultValues: getWidgetInitialValues({ widget: widget! }),
    validationSchema: getWidgetFormValidationSchema(t),
  })

  const {
    handleSubmit,
    setErrors,
    watch,
    formState: { isSubmitting },
  } = form

  const sizesLength = watch('widgetSizes').length

  const onSubmit = handleSubmit(async values => {
    try {
      await handleUpdateWidget({ widgetData: values, widgetId: widget!.id })

      showToast({ message: t('widgets|update.success.widget_updated'), type: 'success' })
    } catch (apiError) {
      const { status } = unpackApiError(apiError)
      const errors = getFormErrors(apiError)

      if (errors) {
        const { publicIfApplicationType, ...rest } = errors

        setErrors({
          ...rest,
          ...(!!publicIfApplicationType && {
            widgetType: publicIfApplicationType,
          }),
        })
      } else if (status === 403) {
        showToast({ message: t('errors|access_denied.action'), type: 'error' })
      } else {
        showToast({ message: t('errors|general'), type: 'error' })
      }
    }
  })

  return (
    <FormProvider {...form}>
      <S.UpdateForm onSubmit={onSubmit}>
        <WppCard size="xl">
          <div slot="header">
            <WppTypography type="xl-heading">{t('widgets|update.title')}</WppTypography>
          </div>
          <Flex direction="column" gap={24}>
            <FormTextInput
              labelConfig={{ text: t('widgets|update.fields.name.label') }}
              placeholder={t('widgets|update.fields.name.placeholder')}
              name="name"
              required
              data-testid="widget-name"
            />
            <FormTextArea
              labelConfig={{ text: t('widgets|update.fields.description.label') }}
              placeholder={t('widgets|update.fields.description.placeholder')}
              name="description"
              required
              data-testid="widget-description"
            />
            <FormTextInput
              labelConfig={{
                text: t('widgets|update.fields.bundle_url.label'),
                description: t('widgets|update.fields.bundle_url.hint'),
                icon: 'wpp-icon-info',
              }}
              placeholder={t('widgets|update.fields.bundle_url.placeholder')}
              name="bundleUrl"
              required
              data-testid="widget-bundle-url"
            />
            <FormGridSizeSelect
              labelConfig={{
                text: t('widgets|update.fields.widget_sizes.label'),
                description: t('widgets|update.fields.widget_sizes.hint'),
                icon: 'wpp-icon-info',
              }}
              name="widgetSizes"
              required
              disabled={sizesLength >= MAX_WIDGET_SIZES}
              buttonTitle={t('widgets|update.fields.widget_sizes.button')}
              dataTestId="widget-sizes"
            />

            <Flex gap={12}>
              <WppButton loading={isSubmitting} type="submit" data-testid="widget-update-submit">
                {t('common|save')}
              </WppButton>
            </Flex>
          </Flex>
        </WppCard>
      </S.UpdateForm>
    </FormProvider>
  )
}
