import { TFunction } from 'i18next'
import * as Yup from 'yup'

import { WidgetFormDTO, WidgetDTO } from 'types/widgets/widget'

export const NAME_FIELD_MAX_CHARS = 64
export const DESCRIPTION_FIELD_MAX_CHARS = 200
const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
export const MAX_WIDGET_SIZES = 7
const URL_MAX_CHARS = 256

export const getWidgetInitialValues = ({ widget }: { widget: WidgetDTO }): WidgetFormDTO => {
  const { name, description, widgetType, bundleUrl, widgetSizes } = widget

  return {
    name,
    description: description || '',
    widgetType,
    bundleUrl: bundleUrl || '',
    widgetSizes: widgetSizes || [],
  }
}

export const getWidgetFormValidationSchema = (t: TFunction<['errors', 'widgets']>) => {
  const widgetSizeSchema = Yup.object().shape({
    x: Yup.number(),
    y: Yup.number(),
  })

  return Yup.object({
    name: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        NAME_FIELD_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: NAME_FIELD_MAX_CHARS,
        }),
      ),
    description: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        DESCRIPTION_FIELD_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: DESCRIPTION_FIELD_MAX_CHARS,
        }),
      ),
    widgetType: Yup.string().required(t('errors|form_validation.required')),
    bundleUrl: Yup.string()
      .trim()
      .required(t('errors|form_validation.required'))
      .max(
        URL_MAX_CHARS,
        t('errors|form_validation.chars_limit', {
          charsCount: URL_MAX_CHARS,
        }),
      )
      .matches(URL_REGEX, t('errors|form_validation.url')),
    widgetSizes: Yup.array()
      .of(widgetSizeSchema)
      .min(1, t('errors|form_validation.required'))
      .max(
        MAX_WIDGET_SIZES,
        t('widgets|update.errors.sizes_exceed', {
          sizesCount: MAX_WIDGET_SIZES,
        }),
      ),
  })
}
