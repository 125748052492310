import { devHubApi } from 'api'
import { WidgetDTO, WidgetFormDTO } from 'types/widgets/widget'

interface Params {
  widgetId: string
  widgetData: WidgetFormDTO
}

export const updateWidgetApi = ({ widgetId, widgetData }: Params) =>
  devHubApi.put<WidgetDTO>(`/widgets/${widgetId}`, widgetData)
