import { UpdateAppFormValues } from 'pages/product/update/types/UpdateAppFormValues'
import { Leaves } from 'types/common/utils'
import { ProductsDictionaryTypes, VersionsDictionaryTypes } from 'types/i18next'
import {
  ProductCommercialModel,
  ProductCategory,
  ProductStatus,
  ProductDataContext,
  OsMetaRoles,
  ProductOnboardingStatus,
  ProductMediaSubcategory,
  ProductProductionSubcategory,
} from 'types/products/enums'
import { ProductSubcategoryType } from 'types/products/product'
import { TenantStatus } from 'types/products/tenant'

export const ProductStatusLabel: Record<ProductStatus, Leaves<ProductsDictionaryTypes>> = {
  [ProductStatus.ARCHIVED]: 'product_statuses.archived',
  [ProductStatus.DRAFT]: 'product_statuses.draft',
  [ProductStatus.ON_VERIFICATION]: 'product_statuses.on_verification',
  [ProductStatus.REJECTED]: 'product_statuses.rejected',
  [ProductStatus.PUBLISHED]: 'product_statuses.published',
}

export const ProductCommercialModelLabel: Record<ProductCommercialModel, Leaves<ProductsDictionaryTypes>> = {
  [ProductCommercialModel.CONTRACT]: 'product_commercial_models.contract',
  [ProductCommercialModel.FIXED_PRICE]: 'product_commercial_models.fixed_price',
  [ProductCommercialModel.FREE]: 'product_commercial_models.free',
  [ProductCommercialModel.PAY_AS_YOU_GO]: 'product_commercial_models.pay_as_you_go',
}

export const ProductCategoryLabel = {
  [ProductCategory.MEDIA]: 'product_category.media',
  [ProductCategory.PRODUCTION]: 'product_category.production',
  [ProductCategory.BRAND]: 'product_category.brand',
  [ProductCategory.COMMERCE]: 'product_category.commerce',
  [ProductCategory.CREATIVE]: 'product_category.creative',
  [ProductCategory.CRM]: 'product_category.crm',
  [ProductCategory.PR]: 'product_category.pr',
  [ProductCategory.DATA]: 'product_category.data',
  [ProductCategory.OTHER_APPS]: 'product_category.other_apps',
} as const

export const ProductSubcategoryLabel: Record<keyof ProductSubcategoryType, Leaves<ProductsDictionaryTypes>> = {
  [ProductMediaSubcategory.STRATEGY]: 'product_subcategory.strategy',
  [ProductMediaSubcategory.PLANNING]: 'product_subcategory.planning',
  [ProductMediaSubcategory.ACTIVATION]: 'product_subcategory.activation',
  [ProductMediaSubcategory.ANALYTICS]: 'product_subcategory.analytics',
  [ProductProductionSubcategory.ADAPT]: 'product_subcategory.adapt',
  [ProductProductionSubcategory.GENERATE]: 'product_subcategory.generate',
  [ProductProductionSubcategory.IDEATE]: 'product_subcategory.ideate',
  [ProductProductionSubcategory.MAKE]: 'product_subcategory.make',
  [ProductProductionSubcategory.OPTIMISE]: 'product_subcategory.optimise',
  [ProductProductionSubcategory.PUBLISH]: 'product_subcategory.publish',
} as const

export const ProductTenantStatusLabel: Record<TenantStatus, Leaves<VersionsDictionaryTypes>> = {
  [TenantStatus.ACTIVE]: 'availability.availability_statuses.active',
  [TenantStatus.INACTIVE]: 'availability.availability_statuses.inactive',
}

export const ProductDataContextLabel = {
  [ProductDataContext.TENANT]: 'product_data_context.tenant',
  [ProductDataContext.CLIENT]: 'product_data_context.client',
  [ProductDataContext.MARKET]: 'product_data_context.market',
  [ProductDataContext.BRAND]: 'product_data_context.brand',
  [ProductDataContext.REGION]: 'product_data_context.region',
  [ProductDataContext.INDUSTRY]: 'product_data_context.industry',
  [ProductDataContext.CUSTOM]: 'product_data_context.custom',
  [ProductDataContext.PROJECT]: 'product_data_context.project',
  [ProductDataContext.MODULE]: 'product_data_context.module',
} as const

export const OsMetaRolesLabel = {
  [OsMetaRoles.VIEWER]: 'viewer',
  [OsMetaRoles.CONTRIBUTOR]: 'contributor',
  [OsMetaRoles.OWNER]: 'owner',
} as const

export const ProductOnboardingStatusLabel = {
  [ProductOnboardingStatus.DRAFT]: 'draft',
  [ProductOnboardingStatus.OS]: 'os',
  [ProductOnboardingStatus.MARKETPLACE]: 'marketplace',
  [ProductOnboardingStatus.OS_AND_MARKETPLACE]: 'os_and_marketplace',
} as const

export const ProductDetailsFormFieldLabel: Partial<Record<keyof UpdateAppFormValues, Leaves<ProductsDictionaryTypes>>> =
  {
    name: 'required_fields_sidebar.fields_labels.product_name',
    benefits: 'upsert.sections.features_and_highlights.fields.benefits.label',
    shortDescription: 'upsert.sections.essentials.fields.short_description',
    applicationCategory: 'upsert.sections.essentials.fields.category',
    regionsMarketsTree: 'upsert.sections.marketing_and_branding.fields.markets',
    commercialModelType: 'upsert.sections.marketing_and_branding.fields.commercial_model',
    ownerName: 'required_fields_sidebar.fields_labels.product_owner_name',
    ownerEmail: 'required_fields_sidebar.fields_labels.product_owner_email',
  } as const
